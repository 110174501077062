button.mat-button-base.mat-stroked-button {
  color: #2d8dab;
  border: 1px solid #2d8dab;
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:disabled {
    color: #b1b1b1;
    border-color: #b1b1b1;
  }
}
button.mat-stroked-button.mat-primary {
  > .mat-button-focus-overlay {
    background-color: black;
  }
}
