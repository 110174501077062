button.mat-button-base.mat-raised-button {
  text-transform: uppercase;
  letter-spacing: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
button.mat-button {
  letter-spacing: normal;
}
