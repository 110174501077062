@use 'abstracts';
@use 'base';
@use 'components';

body {
  background-color: var(--background-light-secondary);
}

.page-center {
  display: grid;
  place-content: center;
  width: 100vw;
  height: 100vh;
}

.container-center {
  display: grid;
  place-content: center;
  width: 100%;
  height: 100%;
}

.text-center {
  text-align: center;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
