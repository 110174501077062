// Fix for weird table styles in @balluff/ngx-standard
.mat-table {
  display: block; // for expandable rows, display:grid is not needed anyway lol
  overflow: initial;
  .mat-row:nth-child(even),
  .mat-row {
    background: white;
  }
  .mat-header-row {
    min-height: 56px;
  }
  .mat-header-cell {
    margin-bottom: 0;
  }
}
